import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Manalikadis | Developer', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Thanos Manalikadis',
  subtitle: "I'm a Software Engineer",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'de.jpg',
  paragraphOne:
    'I am a Junior Software Engineer 👨‍💻 passionate about learning new technologies and developing software solutions that enthuses people. I mainly focus on Web Development 🌐 and currently working as Front-End Developer.',
  paragraphTwo:
    'Graduate with an Electrical Engineering and Computer Science master degree 🎓. My background in engineering ⚙️ helps me solve problems in an efficient, fast way following best practices.',
  paragraphThree: 'Based in Thessaloniki GR 🌍',
  resume: 'https://1drv.ms/b/s!Alehoywu3ILagY0kKAxS7dLiKz512g?e=8LArqy', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'army.png',
    title: 'Military HR Management App',
    info: 'A simplified HR App for managing Military Unit Staff build with Angular.',
    info2:
      'Responsive Admin Panel with support for managing staff information, tasks, days-off, daily roster & export pdf docs.',
    url: 'https://armyhr-app.web.app/',
    repo: 'https://github.com/sakmanal/ArmyUnit-HR-App', // if no repo, the button will not show up
    technologies: [
      {
        value: 'Angular',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/angular.svg',
      },
      {
        value: 'Angular Material',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/material-ui.svg',
      },
      {
        value: 'RxJS',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/reactivex.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'fff.jpg',
    title: 'MovieFinder',
    info: 'A Movie Streaming App build with Angular.',
    info2:
      'Login/Register page, YouTube IFrame Player, Admin Panel for managing movies, Watch-list, JWT Authentication, Role-based Authorization.',
    url: 'https://movfinder.web.app/',
    repo: 'https://github.com/sakmanal/movieFinder', // if no repo, the button will not show up
    technologies: [
      {
        value: 'Angular',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/angular.svg',
      },
      {
        value: 'Youtube IFrame API',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/youtube.svg',
      },
      {
        value: 'RxJS',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/reactivex.svg',
      },
      {
        value: 'JSON Web Tokens',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/jsonwebtokens.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'ImgAnalysisToolkit.png',
    title: 'Image Analysis Toolkit',
    info:
      'My Diploma Thesis, a client-side Image Analysis Toolkit for text document Binarization & Segmentation build with Angular.',
    info2:
      'Pixel manipulation with canvas, Multi-threading Image Processing with Web Workers, User Interaction with HTML Canvas.',
    url: 'https://imganalysis.netlify.app/',
    repo: 'https://github.com/sakmanal/ImgAnalysisToolkit', // if no repo, the button will not show up
    technologies: [
      {
        value: 'Angular',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/angular.svg',
      },
      {
        value: 'Web Workers',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/iconfinder.svg',
      },
      {
        value: 'Fabric.js',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/payoneer.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'ci.jpg',
    title: 'ng-Testing-exercises',
    info: 'A repo with examples and exercises for Angular unit-testing techniques.',
    info2: 'Continuous Integration/Continuous Delivery pipeline with GitHub Actions.',
    url: 'https://sakmanal.github.io/ng-Testing-exercises',
    repo: 'https://github.com/sakmanal/ng-Testing-exercises', // if no repo, the button will not show up
    technologies: [
      {
        value: 'Angular',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/angular.svg',
      },
      {
        value: 'Jasmine',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/jasmine.svg',
      },
      {
        value: 'GitHub Actions',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/githubactions.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'audio.png',
    title: 'RxJS-Audio-Player',
    info: 'Audio Player App build with Angular and RxJS.',
    info2: 'Continuous Integration/Continuous Deployment with Travis CI and Firebase Hosting.',
    url: 'https://rxjs-audio-player.web.app/',
    repo: 'https://github.com/sakmanal/RxJS-Audio-Player', // if no repo, the button will not show up
    technologies: [
      {
        value: 'Angular',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/angular.svg',
      },
      {
        value: 'RxJS',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/reactivex.svg',
      },
      {
        value: 'Jasmine',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/jasmine.svg',
      },
      {
        value: 'Travis CI',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/travisci.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'map.jpg',
    title: 'Map of Greece',
    info:
      'A colorful interactive choropleth map of Greek Administrative Regions Population build with Angular and Leaflet.',
    info2: 'Continuous Integration / Delivery (CI/CD) pipeline using CircleCI, Docker and Azure.',
    url: 'https://greecemap.azurewebsites.net/',
    repo: 'https://github.com/sakmanal/mapGreece', // if no repo, the button will not show up
    technologies: [
      {
        value: 'Angular',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/angular.svg',
      },
      {
        value: 'Leaflet',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/leaflet.svg',
      },
      {
        value: 'CircleCI',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/circleci.svg',
      },
      {
        value: 'Docker',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/docker.svg',
      },
      {
        value: 'Azure',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/microsoftazure.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'inv.png',
    title: 'NgRx Inventory App',
    info: 'A simple Inventory Management App build with Angular and NgRx.',
    info2:
      'State Management with NgRX, Unidirectional data flow with Container and Presentation components pattern, PrimeNg Component Library.',
    url: 'https://sakmanal.github.io/NgRx-Inventory-App',
    repo: 'https://github.com/sakmanal/NgRx-Inventory-App', // if no repo, the button will not show up
    technologies: [
      {
        value: 'Angular',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/angular.svg',
      },
      {
        value: 'NgRx',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/redux.svg',
      },
      {
        value: 'PrimeNG',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/brave.svg',
      },
      {
        value: 'Docker',
        technologyId: nanoid(),
        iconLink: 'https://simpleicons.org/icons/docker.svg',
      },
    ],
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to hire me?',
  btn: '',
  email: 'manalikadis@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/athanasios-manalikadis/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/sakmanal',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
